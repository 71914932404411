.gm-style-iw-chr {
  display: none !important;
}
.gm-style .gm-style-iw-c {
  padding: 0.625rem !important;
  border-radius: 0.25rem !important;
  max-width: 420px !important;
  @media screen and (max-width: 767px) {
    transform: scale(0.8) translateY(-113%) translateX(-65%) !important;
  }
}

.gm-style-iw-d {
  padding: 0 !important;
  overflow: unset !important;
}

.map-marker-hovered-card {
  display: flex;
  gap: 0.625rem;
}

.map-marker-hovered-card-image-container {
  position: relative;
  width: 112px;
  height: 172px;
  flex-shrink: 0;
}

.map-marker-hovered-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.375rem;
  transition: all 0.5s ease;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
}

.map-marker-hovered-card-content {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  flex: 1;
}

.map-marker-hovered-card-title-stars-container {
  display: block;
  font-size: 1.45rem;
}

.map-marker-hovered-card-title-with-stars {
  display: inline;
  word-wrap: break-word;
}

.map-marker-hovered-card-stars {
  display: inline-block;
  white-space: nowrap;
  vertical-align: text-top;
  margin-left: 4px;
}

.map-marker-hovered-card-stars img {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}

.map-marker-hovered-card-rating-container {
  display: flex;
}

.map-marker-hovered-card-feedback {
  color: #3a3a3c;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-transform: capitalize;
}

.map-marker-hovered-card-reviews {
  color: #020202;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  text-transform: capitalize;
}
.map-marker-hovered-card-rating-text {
  background-color: #273454;
  border-radius: 2px 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  margin-left: 10px;
  padding: 7px 6px;
  text-align: center;
  text-transform: capitalize;
}

.map-marker-hovered-card-price-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1;
}

.map-marker-hovered-card-price-container .price {
  font-size: 15px;
  font-weight: 500;
  color: #273454;
}

.arabic-lang .map-marker-hovered-card-rating-text {
  margin-left: unset;
  margin-right: 10px;
}
