.modalBodyContentWrapper {
  display: flex;
}

.modalSidebarContainer {
  width: 65%;
  height: 100%;
  position: relative;
}

.modalSidebarContainer .hotelsList {
  height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0.25rem;
}

.modalSidebarContainer .hotelsList .hotelListItem {
  box-shadow: rgba(99, 99, 99, 0.8) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .modalSidebarContainer {
    display: none;
  }
}
